<template>
  <div class="units-management">
    <div class="title">我的记账单位</div>
    <ym-table-list :data="data"
                   :columns="columns"
                   enableOperatorSlot
                   operatorWidth="150px"
                   :pageLayout="pageLayout"
                   :total="total"
                   :pageNumber="page"
                   @handlePageJump="handlePageJump">
       <template #topInfo>
        <div class="unit-detail">
          <div class="unit-info">
            <div class="unit-name">
              <span class="title">{{ unitDetail &&unitDetail.remainderQuantity}}</span>
              <span class="info">当前余额(个)
                <el-tooltip content="当前余额：创建票券的剩余额度" placement="bottom" effect="light">
                  <i class="el-icon el-icon-question"></i>
                </el-tooltip>
              </span>
            </div>
            <div class="expiry">
              <span class="title">{{unitDetail&&unitDetail.confirmQuantity}}</span>
              <span class="info">已核销(个)
                <el-tooltip content="已核销：被核销的记账单位总数" placement="bottom" effect="light">
                  <i class="el-icon el-icon-question"></i>
                </el-tooltip>
              </span>
            </div>
            <div class="createTime">
              <span class="title">{{ unitDetail&&unitDetail.typeNumber}}</span>
              <span class="info">类型(种)
                <el-tooltip content="类型：拥有的记账单位类型总数" placement="bottom" effect="light">
                  <i class="el-icon el-icon-question"></i>
                </el-tooltip>
              </span>
            </div>
          </div>
          <div class="action-wrap">
              <el-button type="primary" @click="showModal">创建票券</el-button>
          </div>
        </div>
      </template>

      <template #topControl>
        <div class="top-control clearfix">
          <!-- 选择的状态条件标签 -->
          <div class="dropdownlist-tag"
               v-if="showSelectedTag">
            <template v-for="status in selectedStatus">
              <el-tag closable
                      @close="clearSelectedStatus"
                      disable-transitions
                      :key="status">{{status}}</el-tag>
            </template>
          </div>
          <!-- 顶部右侧搜索，刷新和新增 -->
          <!-- <div class="top-control-add">
            <el-button type="primary"
                       @click="showUnitsAddition">新增</el-button>
          </div> -->
          <div class="top-control-refresh">
            <el-button icon="el-icon-refresh"
                       @click="handleRefresh">
            </el-button>
          </div>
          <div class="top-control-search">
            <el-input v-model="search"
                      placeholder="搜索记账单位名称"
                      prefix-icon="el-icon-search"
                      clearable
                      @clear="handleSearch"
                      @keyup.enter.native="handleSearch">
            </el-input>
          </div>
        </div>
        <!-- 状态选择菜单 -->
        <div class='dropdownlist'>
          <el-dropdown trigger="click"
                       @command="selectStatus">
            <el-button>状态</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="all">全部</el-dropdown-item>
              <el-dropdown-item command="used">已使用</el-dropdown-item>
              <el-dropdown-item command="expired">已过期</el-dropdown-item>
              <el-dropdown-item command="using">使用中</el-dropdown-item>
              <el-dropdown-item command="notUsed">未使用</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>

      <!-- <template #status="{row}">
        <template v-if="row.status === '0'">
          <div :style="{color: '#fe8b56'}">已使用</div>
        </template>
        <template v-else-if="row.status === '1'">
          <div>已过期</div>
        </template>
        <template v-else-if="row.status === '2'">
          <div :style="{color: '#4674e6'}">使用中</div>
        </template>
      </template> -->
      <template #endTime="{row}">
        {{row.beginTime | timeFilter('YYYY.MM.DD')}} - {{row.endTime | timeFilter('YYYY.MM.DD')}}
      </template>

      <template #time="{row}">
        <template v-if="row.time">{{row.time | timeFilter('YYYY.MM.DD HH:mm:ss')}}</template>
        <template v-else>-</template>
      </template>

      <template #operatorCol="{row}">
        <el-button class="operator-btn ym-icon-liulan"
                   @click.stop="showUnitDetail(row)">详情</el-button>
      </template>

    </ym-table-list>

      <tickets-addition v-if="showAddition"
        @create="create"
        @hide="hiddenModal('showAddition')"></tickets-addition>

  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import TicketsAddition from '../tickets/ticketsAddition/index.vue'

export default {
  components: {
    YmTableList,
    TicketsAddition
  },
  data() {
    return {
      // 列表数据项
      data: [],
      // 列表表头配置项
      columns: [{
        label: '记账单位名称',
        prop: 'name',
        align: 'center'
      },
      {
        label: '持有总数(个)',
        prop: 'sum',
        align: 'center'
      },
      {
        label: '已使用(个)',
        prop: 'useQuantity',
        align: 'center'
      },
      {
        label: '当前余额(个)',
        prop: 'remainderQuantity',
        align: 'center'
      },
      {
        label: '已核销(个)',
        prop: 'confirmQuantity',
        align: 'center'
      },
      {
        label: '有效期',
        prop: 'endTime',
        align: 'center',
        enableColSlot: true,
        width: '200px'
      },
      {
        label: '状态',
        prop: 'status',
        align: 'center'
      },
      {
        label: '获取时间',
        prop: 'time',
        align: 'center',
        enableColSlot: true
      }],
      pageLayout: 'prev, pager, next, jumper',
      total: 10, // 数据总数
      selectedStatus: null, // 已选择的状态
      showSelectedTag: false, // 是否存在已选择的状态
      search: '', // 搜索内容
      showAddition: false, // 显示新增
      size: 10,
      page: 1,
      unitDetail: {
        remainderQuantity: 0,
        typeNumber: 0,
        confirmQuantity: 0
      }
    }
  },
  watch: {
    selectedStatus() {
      this.showSelectedTag = this.hasSelected()
    }
  },
  mounted() {
    this.getTotal()
  },
  methods: {
    // 对搜索框及状态选择标签进行搜索
    handleSearch() {
      this.page = 1
      this.getList()
    },
    // 清空搜索条件(包括状态选择和搜索文本)
    handleRefresh() {
      this.selectedStatus = null
      this.search = ''
      this.handleSearch()
    },
    // 显示新增记账单位窗口
    showModal() {
      this.showAddition = true
      this.$bus.$emit('blurScreen')
    },
    // 销毁新增记账单位窗口
    hiddenModal() {
      this.$bus.$emit('clearBlur')
      this.showAddition = false
    },
    // 处理新增票券
    create(data) {
      this.$http.ticket.addTicket({
        name: data.name,
        accountingId: data.type,
        accountingPerTicket: data.contain,
        quantity: data.amount,
        type: data.ticketType
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.hiddenModal('showAddition')
          this.$router.push('/tickets/ticketsManagement')
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 是否存在筛选条件
    hasSelected() {
      if (this.selectedStatus) {
        return true
      } else {
        return false
      }
    },
    // 清楚筛选条件
    clearSelectedStatus() {
      this.selectedStatus = null
      this.handleSearch()
    },
    // 筛选状态条件
    selectStatus(command) {
      switch (command) {
        case 'all':
          this.selectedStatus = null
          break
        case 'used':
          this.selectedStatus = []
          this.selectedStatus.push('已使用')
          break
        case 'expired':
          this.selectedStatus = []
          this.selectedStatus.push('已过期')
          break
        case 'using':
          this.selectedStatus = []
          this.selectedStatus.push('使用中')
          break
        case 'notUsed':
          this.selectedStatus = []
          this.selectedStatus.push('未使用')
          break
        default:
          this.selectedStatus = null
          break
      }
      this.handleSearch()
    },
    showUnitDetail(row) {
      this.$router.push({
        name: 'units-detail-person',
        query: {
          id: row.accountingId
        }
      })
    },
    // 处理页码跳转
    handlePageJump(page, pageSize) {
      this.page = page
      this.getList()
    },
    getList() {
      this.$http.unit.getPersonUnitList({
        size: this.size,
        page: this.page,
        name: this.search,
        statusList: this.selectedStatus
      }).then(res => {
        if (res.data.code === 0) {
          this.data = res.data.data.records
          this.total = res.data.data.total
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    async getTotal() {
      const { data } = await this.$http.unit.getPersonUnitList({
        size: this.size,
        page: this.page,
        name: this.search,
        statusList: this.selectedStatus
      })
      if (data.code === 0) {
        this.data = data.data.records
        this.total = data.data.total
        this.$http.unit.unitTotal().then(res => {
          if (res.data.code === 0) {
            this.unitDetail = res.data.data
          }
        })
      } else {
        this.$message({
          type: 'error',
          message: data.message
        })
      }
    }
  }

}
</script>

<style lang="scss">
.units-management {
   .unit-detail {
    padding: 24px 0 26px 0;
    .unit-info {
      display: flex;
      align-items: center;
      font-size: $--font-size-medium;
      width:100%;
      padding-bottom: 34px;
      border-bottom:1px solid #e5e8f3;
      .unit-name,.expiry,.createTime{
        flex-grow: 1.0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .title {
        display: block;
        margin-bottom: 10px;
        font-size: 42px;
        font-family: DINPro;
        font-weight: bold;
        color:  $--color-text-primary;
        line-height: 40px;
      }
      .info {
        display: block;
        color:#B3B4BA;
      }
    }
    .action-wrap{
      padding: 19px 30px 0;
    }
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    color: $--color-text-primary;
    margin-bottom: 30px;
  }
  .top-control {
    margin-top: 16px;
    margin-bottom: 16px;
    .dropdownlist-tag {
      float: left;
      margin-left: 32px;
      .el-tag {
        position: relative;
        width: 110px;
        height: 40px;
        font-size: 14px;
        line-height: 41px;
        background-color: $--color-primary-light-9;
        border: none;
        border-radius: 6px;
        color: $--color-text-primary;
        padding-left: 20px;
        padding-right: 11px;
        cursor: default;
        .el-icon-close {
          position: absolute;
          right: 11px;
          top: 50%;
          margin-top: -8px;
          color: $--color-text-primary;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
    &-search {
      float: right;
      width: 240px;
      margin-right: 24px;
      .el-input {
        border-radius: 6px;
      }
    }
    &-refresh {
      float: right;
      margin-right: 24px;
      .el-button {
        padding: 8px 11px;
        .el-icon-refresh::before {
          font-size: 20px;
        }
      }
    }
    &-add {
      float: right;
      margin-right: 30px;
      font-size: $--font-size-medium;
      font-weight: $--font-weight-primary;
    }
  }
  .dropdownlist {
    padding-top: 19px;
    padding-bottom: 19px;
    border-top: 1px solid #e5e8f3;
    .el-button {
      width: 90px;
      height: 41px;
      margin-left: 32px;
    }
  }
}
</style>
